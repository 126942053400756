.two-columns {
    @include desktop {
        .ProductListWidget {
            --product-list-widget-page-grid-template-columns: repeat(2, 1fr);
        }
    }
}

.LayoutGroupWidget-Item {
    &_specialProducts {
        .swiper {
            &-container {
                @include desktop {
                    padding: 6rem 0 0 0 !important;
                    margin-top: -5rem;
                }

                @include mobile {
                    padding: 0 0 5rem 0 !important;
                    margin-bottom: -2rem;
                }
            }

            @include desktop {
                &-button {
                    &-prev,&-next {
                        top: 3rem !important;
                    }
            
                    &-prev {
                        right: 9.5rem !important;
                        left: auto !important;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100px;
                            height: 1px;
                            border-top: 1px dashed #000;
                            left: 4.5rem;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
            
                    &-next {
                        right: 3rem !important;
                        left: auto !important;
                    }
                }
            }
        }

        .ProductCard { 
            &-Picture {
                @include desktop {
                    transform: scale(1) !important;
                    padding-bottom:75% !important;
                    min-height: 23rem;
                }
                @include mobile {
                    padding-bottom: 85% !important;
                }
            }

            @include mobile {
                &-PriceWrapper {
                    text-align: center !important;
                }
            }
            
            .SaasProductTitleBlock {
            
                @include mobile {
                    &-AttributeSuper,&-AttributeSub {
                        display: inline-block;
                        width:100%;
                        text-align: center;
                    }
                }

                h2 {
                    font-size: 1.6rem !important;
                    line-height: 1.2 !important;
                    text-align: center !important;
                }
                
            }
            &-Content {
                margin-top:auto !important;
            }
            &-Footer {
                max-width: 20rem;
                margin: auto;
                margin-bottom: 1rem;
            }

            .SaasProductLabels {
                &-ProductLabelGroup_position_top{
                    &-right {
                        @include desktop {
                            display:block !important;
                            top: 2rem !important;
                            right: 2rem !important;

                            .SaasProductLabels {
                                &-ProductDiscountLabelPercentage {
                                    font-size: 3rem;
                                }
                            }
                        }
                    }
                }

                &-ProductDiscountLabel {
                    @include desktop {
                        --product-discount-label-border: 6px solid #727464;
                        --product-discount-label-width: 97px;
                        --product-discount-label-height: 97px;
                        font-size: 1.4rem;

                        &:before {
                            display:block !important;
                        }
                
                        &:after {
                            display:block !important;
                        }
                    }
                }
            }
        }
    }
}

.SaasSliderWidget {

    @include desktop {
        &-Heading {
            text-align:left !important;
        }
    }

    &_isWithTwoItems{

        .swiper-container {
            @include desktop {
                padding: 6rem 0 0 0 !important;
                margin-top: -6rem;
            }
        }

        .ProductCard {
            &-Picture {
                @include desktop {
                    padding-bottom: 170% !important;
                }
            }
        }
        
        @include desktop {

            .swiper-button-prev,.swiper-button-next{
                top: 3rem !important;
            }
            .swiper-button{
                &-next {
                    right: 0 !important;
                    left: auto !important;
                }
                &-prev {
                    right: 7rem !important;
                    left: auto !important;
                }
            }
        }
    }
}