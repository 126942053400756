.swiper-button,
.swiper-button {

    @include desktop {
        &-prev, &-next {
            width: 4.5rem !important;
            height: 4.5rem !important;

            &:after {
                width: 4.5rem !important;
                height: 4.5rem !important;
            }
        }
    }

    @include mobile {
        &-prev, &-next {
            width: 3rem !important;
            height: 3rem !important;

            &:after {
                width: 3rem !important;
                height: 3rem !important;
            }
        }
    }
}