.EventCalendar {
    --event-calendar-default-padding: 1rem 1.5rem;
    margin-bottom: 5rem;
    text-align: left !important;

    .ContentWrapper  {
        h2 {
            --content-block-title-font-size: 2.6rem;
            font-size: var(--content-block-title-font-size);
            text-align: left;
        }
    }
    
    &-Container {
        display: grid !important;

        @include desktop {
            grid-template-columns: repeat(3,1fr);
            grid-gap: 4rem;
        }
        @include mobile {
            grid-template-columns: repeat(1,1fr);
            grid-gap: 2rem;
        }

        .EventCalendarItem {
            --event-calendar-default-spacing: 0;

            width:auto;
            
            &-Container {
                margin: 0;
            }

            &-Link {
                font-size: 1.8rem;

                &:after {
                    font-weight: 100;
                    font-size: 1.4rem;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                }
            }   

            &-Content {
                font-size: 1.6rem;
            }
        }     
    }
}