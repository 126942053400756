.BannerItem {
    &-Title {
        text-transform: inherit;
    }
}

.StoreLocator {
    &-Description {
        font-size: 1.6rem;
        line-height: 1.75;
    }

    &-SearchFilter {
        --store-locator-search-filter-background: #A6905C;
    }
}

.StoreList {
    &-Content {
        --store-list-content-row-gap: 2rem;
        --store-list-content-column-gap: 2rem;

        .StoreListItem {
            --button-background: var(--primary-base-color);

            &-Link {
                &:hover {
                    .StoreListItem-ContentHeading h3 {
                        color: #272323;
                    }
                }
            }

            &-ContentHeading {
                --store-list-item-content-border-color: #eee;

                h3 {
                    color: #727464;
                    font-size: 1.8rem;
                }
            }

            &-AddressInfo {
                font-size: 1.6rem;
            }

            &-Thumbnail {
                padding-bottom: 75%;

                .Image {

                    &-Image {
                        height: auto !important;
                        width: auto !important;
                        object-fit: contain;
                        min-width: 100%;
                        min-height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

.StoreDetailUsp {
    --store-detail-usp-icon-color: var(--primary-success-color);
    --store-detail-usp-icon-font-size: 5rem;
}
