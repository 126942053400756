.CategoryPage_isShop {
    .LayoutGroupWidget-Item {
        .ContentWrapper_isFluid {
            margin-bottom: var(--content-wrapper-margin-bottom);

            @include desktop {
                margin-bottom: var(--content-wrapper-margin-bottom);
            }
        }
    }
}
.ContentWrapper {
    --content-wrapper-margin-bottom: 2rem;

    @include desktop {
        --content-wrapper-margin-bottom: 5rem;
    }
}

.LayoutGroupWidget-Container {
    --layout-group-widget-grid-gap: 2rem;

	@include desktop {
		--layout-group-widget-grid-gap: 5rem;
	}
}

.BannerGroup-Container {
    &.square-images {
        .swiper {
            @include mobile {
                &-container {
                    padding: 0 0 6rem 0 !important;
                    margin-bottom: -3rem;
                }
            }
        }
    }

    @include mobile {
        &_IsBranditem {
            &.square-images {
                .swiper-container {
                    padding: 0 0 10rem 0 !important;
                    margin-bottom: -3rem;
                }
            }

            .BannerItem {
                &-ContentBlock {
                    bottom: -3rem !important;
                }
            }
        }
    }

    .BannerItem {
        &-Title {
            font-weight: 500;
        }
    }

    &.square-images {
        .BannerSlider {
            &-Item {
                --banner-slider-height: auto;
                padding-bottom: 100%;

                .BannerItem {
                    &-ContentBlock {
                        --banner-slider-item-content-block-margin: auto 0 0;
                        --banner-slider-item-content-block-padding: 0;
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }

        .BannerItem {
            &_isGradient::after {
                display: none;
            }

            &_isSlider {
                .BannerItem-ContentBlock {
                    --banner-slider-item-content-padding: 0;
                    position: absolute;
                    bottom: 0;
                }
            }

            &-Link {
                width: 100%;
                text-align: center;
                border: 0;
                transition: 200ms;
            }
        }

        .BannerSlider {
            &-Item {
                height: auto;
                padding-bottom: 100%;
            }
        }
    }

    &.transparent-buttons {
        .BannerItem {
            &_isGradient::after {
                display: none;
            }

            &-Link {
                --button-background: transparent;
                --button-hover-background: var(--button-background);
                width: 100%;
                text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.57);
                opacity: 1;
                backdrop-filter: blur(3px);
                -webkit-backdrop-filter: blur(3px);
                border: 0;
                border-radius: 0;
                transition: 200ms all;
                text-align: center;

                &:hover {
                    background: rgba(255,255,255,0.3);
                }
            }
        }
    }

    &.green-buttons {
        .BannerItem {
            &-Link {
                --button-background: #727464;
                --button-hover-background: #8C8E7E;
                --button-hover-border: #8C8E7E;
            }
        }
    }
}

// ---------------------------------------
// Homepage
// ---------------------------------------

// 2 Columns
.BannerBlock-Container_column_2 {
    .BannerItem {
        &-ContentBlock {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

.LayoutGroupWidget {
    &-Container {
       &.text-block {
            .LayoutGroupWidget-Item {
                &:first-of-type {
                    .BannerBlock-Item {
                        &_type_column_1 {
                            --banner-block-item-height-col-2: auto;
                            @include desktop {
                                flex-direction: row-reverse;
                            }

                            @include mobile {
                                flex-direction: row-reverse;
                            }

                            .BannerItem-ContentBlock {
                                margin: 0 auto;
                            }
                        }
                    }

                    .BannerItem {
                        background: #A6905C;

                        @include desktop {
                            padding: 6rem 3rem 3rem 3rem;
                        }

                        &:before {
                            position: absolute;
                            width: 5rem;
                            height: 5rem;
                            top: 0;
                            left: 3rem;
                            content: '';
                            background-image: url('../../img/emblem-white.svg');
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;

                            @include mobile {
                                left: 1rem;
                            }
                        }

                        &-Image {
                            position: relative;
                            max-width: 260px;
                            object-position: right;


                            @include desktop {
                                margin-top: -3rem;
                            }


                            img {
                                max-width: 100% !important;
                                object-fit: contain;
                                object-position: right;
                            }
                        }

                        &_isGradient::after {
                            display: none;
                        }

                        &-Link {
                            --button-background: #272323;
                            border: 0;
                            border-radius: 5px;
                            font-size: 1.4rem;
                            padding: 1.2rem 1.8rem;

                            margin-left:0;
                            margin-right:auto;
                        }

                        &-ContentBlock {
                            --banner-item-content-padding: 0 3rem 0 0;

                            .BannerItem-Title {
                                color: #272323;
                                font-weight: 500;
                                margin: 0 0 1rem 0 !important;
                            }

                            .BannerItem-Subtitle {
                                color: #fff;
                                font-weight: 400;
                                font-style: italic;
                                margin: 0 0 1rem 0;
                            }

                            .BannerItem-Content {
                                --banner-item-content-font-size: 1.4rem;
                                color: #272323;
                                font-weight: 300;
                                margin: 0 0 1rem 0;
                                font-style: inherit;
                            }
                        }
                    }
                }

                &:last-of-type {
                    background: #fff;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    padding: 2rem 2rem 0 2rem;

                    @include mobile {
                        box-shadow:none;
                        padding: 0;
                    }

                    p {
                        --paragraph-font-size: 1.6rem;
                        line-height: 1.8;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

.BannerBlock-Container_column_2 {
    .BannerItem {
        &-Link {
            margin-left: auto;
            background-color: rgba(0, 0, 0, 0.35);
            border-color: rgba(255, 255, 255, 0.35);

            @include desktop {
                margin-right: 0;
            }

            @include mobile {
                margin-right: auto;
            }
        }

        &-ContentBlock {

            @include desktop {
                text-align: left;
            }

            @include mobile {
                text-align: center;
            }
        }
    }
}


@include desktop {
    // Main Banner
    .homepage-banner {
        --banner-slider-height: 45rem !important;

        .BannerItem {
            &-ContentBlock {
                padding: 0 2.5rem 5rem 1.5rem !important;
            }

            &-Title {
                --banner-slider-item-title-font-size: 3.2rem;
            }

            &-ContentBlock {
                --banner-slider-item-content-padding: 0 1.5rem 4rem 1.5rem;
                --banner-slider-item-content-block-margin: auto auto 0;
            }
        }
    }
}

// ---------------------------------------
// Category
// ---------------------------------------

// @TODO: Add class for custom pages (wijnlanden)
.CategoryPage:not(.CategoryPage_isShop) {
    .SaasButtonWidget-Button {
        --saasbuttonwidget-button-background: #FCC100;
        --saasbuttonwidget-button-color: #000;
        border-radius: 5px;
    }

    .BannerItem {
        padding: 1rem !important;
        border: 1px solid #EEEEEE;

        &-Image {
            top: 1rem !important;
            right: 1rem !important;
            bottom: 1rem !important;
            left: 1rem !important;
            width: calc(100% - 2rem) !important;
            height: calc(100% - 2rem) !important;
            overflow: hidden;
            background-color: #272323;

            &:not(img) {
                > img {
                    top: -0.2rem !important;
                    right: -0.2rem !important;
                    bottom: -0.2rem !important;
                    left: -0.2rem !important;
                    width: calc(100% + 0.4rem) !important;
                    height: calc(100% + 0.4rem) !important;
                    transition: 200ms;
                }

                &:hover {
                    > img {
                        filter: blur(0);
                        top: 0 !important;
                        right: 0 !important;
                        bottom: 0 !important;
                        left: 0 !important;
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }

        &-ContentBlock {
            display: flex;
            width: 100%;
            align-items: end;
            padding: 0 !important;
        }

        &-Link {
            --button-background: #727464;
            --button-hover-background: #8C8E7E;
            --button-hover-border: #8C8E7E;
            width: 100%;
            text-align: center;
            border: 0;
            transition: 200ms;
        }
    }

    .BannerGroup-Container {
        &.square-images {
            .BannerItem_isSlider {
                padding: 0 0 100% 0 !important;
                border: none !important;

                .BannerItem-Image {
                    top: 0 !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    left: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        &.wine_countries_overview {
            .BannerItem {
                &-Link {
                    --button-background: #272323 !important;
                    --button-hover-background: #8C8E7E !important;
                    --button-hover-border: #8C8E7E !important;
                    text-align: center;
                    margin: 0 auto 3rem auto;
                    border-radius: 0.3rem;
                    min-width: 18.5rem;
                    width: unset !important;
                    border: 1px solid #C3C3C3 !important;
                }
            }
        }

        &.wine_countries_houses {
            .BannerItem {
                &-Link {
                    --button-background: #727464;
                    --button-hover-background: #8C8E7E;
                    --button-hover-border: #8C8E7E;
                    width: 100%;
                    text-align: center;
                    margin: 0 auto;
                    border: none !important;
                }
            }
        }
    }

    .winehouse_banner_group {
        .BannerBlock-Item {
            padding: 0 !important;
            border: none !important;

            .BannerItem-Image {
                top: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                left: 0 !important;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

main {
    .BannerGroup-Container.square-images {
        .BannerItem-ContentBlock {
            --banner-item-content-padding: 0;
            > a {
                border-radius: 0px;
            }
        }
    }
}

.StoreListPage {
    @include desktop {
        .BannerGroup {
            &-Container {
                margin-bottom: 4rem;
            }
        }

        // Main Banner
        .homepage-banner {
            --banner-slider-height: 50rem !important;
            margin: 0 0 4rem;
            width: 100%;
            max-width: 100%;
        }

        .BannerItem {
            &-Link {
                font-size: 1.4rem;
            }

            &-ContentBlock {
                --banner-slider-item-content-padding: 0 1.5rem 4rem 1.5rem;
            }
        }
    }
}

@include desktop {
    .LayoutGroupWidget-Item {
        &_hasMarginTop {
            margin-top:5rem;
        }
    }
}
@include mobile {
    .LayoutGroupWidget-Item{
        &_isMagazine {
            margin-left: calc(-1 * var(--content-wrapper-padding-inline));
            width: calc(100% + calc(2 * var(--content-wrapper-padding-inline)));
            margin-right:calc(-1 * var(--content-wrapper-padding-inline));

            .BannerBlock {
                &-Item {
                    padding: 2rem 1.5rem 1.5rem 1.5rem;

                    .BannerItem {
                        &-ContentBlock {
                            width: 56%;
                            --banner-item-content-padding: 0 1rem 0 1rem !important;
                            margin: 0 !important;
                        }
                        &-Title {
                            max-width: 18rem;
                            text-align: left;
                            padding-left: 5rem;
                        }
                        &-Image {
                            max-width: 44% !important;
                        }
                        &:before {
                            left:1rem;
                        }
                    }
                }
            }
        }
    }
}

// shop in shop banners
.SaasCmsPageList {
    &-Item {
        @include mobile {
            --cmspagelistitem-padding: 0 0rem 2rem 0rem;
        }
    }
}
