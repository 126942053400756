.StoreLocator {
    &-Container {
        --store-locator-search-filter-background: #A6905C;
    }

    &-Description {
        h2,p {
            color:#fff;
        }
    }
}