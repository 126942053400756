.MyAccountMyWishlist {
    &-Products {
        top: 2rem !important;
        margin-bottom: 2rem;
        
        .ProductCard {
            align-items: center;
            padding: 1rem !important;

            &-Figure {
                
                .Image {
                    padding-bottom: 66% !important;
                    overflow: unset;
                    min-height: 22rem;

                    img {
                        max-height:22rem;
                        min-height: 22rem;
                    }
                }
            }
            .WishlistItem {
                &-FigureWrapper {
                    width: 100%;

                    h4 {
                        text-overflow: initial;
                        overflow: auto;
                        white-space: initial;
                    }
                    .ProductReviewRating {
                        margin:auto;
                    }
                    
                }

                &-RatingPlaceholder,&-Brand  {
                    display: none;
                }

                &-Remove {
                    right: 1rem;
                    top: 1rem;
                    position: absolute;
                }

                &-Content {
                    padding:0 !important;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: auto;
                }

                &-RowWrapper {
                    align-items: center;
                    flex-direction: column;
                }

                &-Price {
                    min-height: auto;
                    margin-bottom: 0;
                }

                &-CommentField {
                    margin-top: 0 !important;
                    width: 100%;
                }

                &-ActionWrapper {
                    width: 100%;
                }
                &-AddToCart {
                    &.Button {
                        padding-left: 3rem;
                        &:hover {
                            padding-left: 0;
                            padding-right: 0;
                            --button-hover-color: #000;
                        }
                    }
                }
            }
            
            .WishlistItemOptions{
                &-List {
                    display: none;
                }
            }
        }
    }
}
