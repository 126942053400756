.LayoutGroupWidget {
    &-Item {
        &_isRecepten {
            .CmsBlock {
                &-Wrapper {
                    h2 {
                        span {
                            color: var(--primary-dark-color);
                        }
                    }

                    > h2 {
                        font-size: 2.6rem;
                        margin: 1rem 0;
                    }
                }
            }

            .BannerBlock {
                &-Container {
                    .BannerBlock{
                        &-Item { 
                            background-color: #fff;
                            border: 1px solid #ddd;

                            @include desktop {
                                --banner-block-item-height-col-2: 32.2rem; 
                            }

                            @include mobile {
                                --banner-block-item-height-col-2: 26rem;
                            }

                            .BannerItem {
                                &-Image {
                                    left: 1rem;
                                    top: 1rem;
                                    bottom: 1rem;
                                    width: calc(100% - 2rem);
                                    height: calc(100% - 2rem);
                                }
                                
                                &-ContentBlock {
                                    background: rgba(255, 255, 255, 0.5);
                                    padding: 1rem 2rem 1rem 2rem;
                                    left: 1rem;
                                    bottom: 1rem;
                                    width: calc(100% - 2rem);
                                    backdrop-filter: blur(3px);
                                }

                                &-Content {
                                    display: none;
                                }

                                &-Link {
                                    background: #272323;
                                    border: none;
                                    margin: auto;  
                                }
                            }

                            &:after {
                                display: none;   
                            }
                        }
                    }
                }
            }
        }
    }
}