.SaasGoogleMaps {
    
    .gm-style-iw-d {
        overflow: inherit !important;
    }

    &-InfoWindow {

        .ContentBlock {

            &-Title {
                padding: 0.5rem 1rem;
                background-color: transparent;
                border-bottom: 0.1rem solid #ddd;

                h2 {
                    font-size: 1.6rem !important;
                    text-transform: none;
                    font-weight:700 !important;
                }
            }
            
            &-Content {
                padding: 1rem !important;
            }
        }
    }
}