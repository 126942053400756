.VideoWidget {
	--content-block-title-padding: 1rem 0;
	--content-block-title-text-transform: capatilize;
	--content-block-title-font-size: 3rem;
	--video-widget-item-content-heading-size: 2rem;


	&-ListItem {
		background-color: #A6905C;

		&:hover {
			background-color: #927d4a !important;
		}
	}

	&-ItemContent {

		> h4 {
			--video-widget-item-content-heading-size: 1.8rem;
			line-height: 1.2;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 1rem;

			@include mobile {
				font-size:1.6rem;
			}
		}
	}
}