
// General AddToCard Styling for entire shop
.ProductActions {
    &-AddToCartWrapper {
        .ProductWishlistButton {
            display: block !important;
        }
    }

    &-AddToCart {
        --button-hover-background: var(--button-background);
        --button-hover-color: var(--button-color);
        --button-height: 5.6rem;
        --button-hover-height: var(--button-height);
        --button-extra-padding-for-icon: 2rem;
        --button-padding: 3.3rem;
        --button-padding-with-icon: calc(var(--button-padding) + var(--button-extra-padding-for-icon));
        --button-border-radius: 0 0.5rem 0.5rem 0;
        --button-hover-border-radius: var(--button-border-radius);

        &:hover {
            opacity: .7;
        }

        @include mobile {
            --button-height: 4.8rem;
            --button-hover-height: var(--button-height);
        }
        border: none;

        > span {
            &:first-of-type {
                position: relative;
            }
        }
    }
}

// General SaasInputSelectField Styling for entire shop
.SaasInputSelectField {
    &-Input {
        border: none !important;
        box-shadow: -1px 1px 3px rgba(0,0,0,0.13);
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

    &-Options {
        bottom: 4.4rem !important;
    }
}

// Specific SaasInputSelectField and AddToCard Styling for Productpage
.ProductPage {
    --button-height: 5.6rem;
    --button-padding: 3.3rem;

    @include mobile {
        --button-height: 4.8rem;
        --button-hover-height: var(--button-height);
    }
    .ProductAttributeIcons{
        .ProductActions {
            &-Qty {
                margin-inline-end: 0 !important;
            }

            &-Price {
                @include mobile {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                }

                .ProductPrice-PriceValue {
                    @include mobile {
                        margin-top: 0;
                    }
                }
            }

            &-Schema {
                margin-bottom: 1rem;
                width: 100%;

                @include mobile {
                    margin-bottom: 0;
                    width: auto;
                }
            }

            &-AddToCart {
                padding-right: var(--button-padding-with-icon);
                margin-right: 0 !important;

                &:hover {
                    padding-right: var(--button-padding-with-icon);
                    padding-left: var(--button-padding);
                }

                @include mobile {
                    --button-padding: 1rem;
                }
            }

            &-ActionButtons {
                --wishlist-heart-size: 3.6rem;
                display: flex;
                order: 4;
                align-items: center;
                justify-content: center;
                margin-left: 1.5rem;

                .ProductWishlistButton {
                    margin: 0;
                }
            }

            &-AddToCartWrapper.FixedElement-Bottom {
                .ProductActions {
                    &-PriceWrapper {
                        @include mobile {
                            margin-right: 0;
                        }
                    }

                    &-Schema {
                        margin-bottom: 0;
                        width: auto;
                    }
                }

                .ProductActions-AddToCart {
                    @include ultra-narrow-desktop {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    // Specific WIJNKRING layout styling
    .ProductAttributeIcons {
        /**
         * Temp fix for non-plugin version of add to cart on right
         */
        .ProductActions {
            flex-direction: row;
            padding-inline-start: 2rem;
            width: 100%;

            .SaasProductTitleBlock {
                display: none;
            }

            &-ShortDescription {
                display: none;
            }

            &-AddToCartWrapper {
                display: flex;
                flex-wrap: wrap;

                @include mobile {
                    padding: 1rem;
                }
            }

            &-PDFDownLoader {
                display: none;
            }
        }
    }
    .ProductAttributeIcons{
        .SaasInputSelectField {
            --input-type-number-height: 5.6rem;

            @include mobile {
                --input-type-number-height: 4.8rem;
            }
        }
    }
}
