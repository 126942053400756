.NewsletterSubscription {
  --primary-error-color: black !important;

  .FieldForm {
      .Field {
        &-Message {
          display: none;
        }
      }
  }
}
