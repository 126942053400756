.Checkout {
    #street2_wrapper select {
        option {
          @include mobile {
              overflow: hidden;
          }
        }
      }

    &-Wrapper {
        &:not(.Checkout-Wrapper_isShipping) {
            --content-wrapper-width: 1020px;
        }
    }

    .CheckoutBilling {
        &-Newsletter {
            align-items: center;
            margin-bottom: 2rem;

            &-Checkbox {
                margin-top: 0;
            }
        }

        &-TermsAndConditions {
            .CheckoutBilling-TACLabel {
                margin-top: 0;
            }
        }

        &-Checkbox,
        &-Newsletter-Checkbox,
        &-TermsAndConditions {
            .input-control {
                --checkbox-width: 2.5rem;
                --checkbox-height: 2.5rem;
            }

            span {
                font-size: 1.4rem;
            }
        }

        .CheckoutPayments {
            &-Methods {
                width: 100%;
            }

            .Ideal {
                width: 100%;
            }

            .IdealSelect-Select {
                flex: 1;
            }
        }

        &-Button {
            --button-border: var(--button-background);
        }

        &-TACLink {
            color: var(--primary-dark-color);
            text-decoration: underline;
            font-weight: 400;
        }
    }

    .CheckoutShipping-Button {
        --button-border: var(--imported_buttons_background_color);
    }

    .CheckoutDeliveryOption {
        .Field [type='checkbox'] {
            + .input-control {
                &::after {
                    transform: rotate(45deg) scale(1.3);
                    left: calc(50% - 7px);
                }

                &:hover {
                    &:after {
                        --checkmark-color: #00A43C !important;
                    }
                }
            }

            &:checked + .input-control {
                background-color: #fff !important;

                &:after {
                    --checkmark-color: #00A43C !important;
                }
            }
        }

        &-Button {
            align-items: center;

            .input-control {
                --checkbox-width: 3rem;
                --checkbox-height: 3rem;
                --checkbox-border-radius: 1.5rem;
            }
        }
    }

    .CheckoutOrderSummary {
        &-SummaryItem {
            padding: 0;
            border-bottom: none !important;

            &_isTotal {
                padding: 1.5rem 0;
                margin-top: 1rem;
                border-bottom: 1px solid var(--primary-divider-color) !important;
            }
        }
    }
}
