// Checkout success page and order/success page
.Checkout_isSuccess, .SuccessPage {
    .SuccessContainer {
        &-Item {
            &--content {
                h1 {
                    span {
                        color: var(--primary-dark-color);
                    }
                }

                ul {
                    li {
                        > span {
                            &:not(.icon) { 
                                a {
                                    color: var(--primary-dark-color);
                                }
                            }
                        }
                    }
                }
            }
        }

        &-ButtonRow {
            .Button {
                --button-background: var(--primary-base-color);

                &:first-of-type {
                    --button-background: #FCC100 !important;
                    color: #000 !important;
                    --button-hover-background: #E3A800;

                }
            }
        }
    }
}