.GiftCard-FormSubmitButton,
.CartCoupon-Button {
    --button-color: #fff;
    --button-background: var(--primary-base-color);
}

.CartOverlay-CheckoutButton,
.CartPage-CheckoutButton {
    --button-border: var(--button-background);

    svg {
        transition: fill 0.25s ease-out;
    }

    &:hover {
        svg {
            fill: #fff;
            transition: fill 0.25s ease-out;
        }
    }
}

.CartPage {
    &-CheckoutButton {
        margin-bottom: 0 !important;
    }

    .CheckoutOrderSummary {
        padding-left: 0;
        padding-right: 0;

        &-Header {
            margin-bottom: 2rem;
        }

        &-OrderTotals {
            @include mobile {
                --checkout-order-summary-order-totals-padding: 1.5rem;
            }
        }

        &-SummaryItem {
            padding: 0;

            &_isTotal {
                padding: 1.5rem 0;
                margin-top: 1rem;
            }
        }
    }

    &-TableHead {
        span {
            &:first-child {
                opacity: 0;
            }
        }
    }

    &-GiftCard {
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        padding: 2rem;
    }
}
