.Footer {
    --footer-content-border-color: #707070;
    --footer-column-newsletter-background-color: #ed1941;

    &-Columns {
        &.ContentWrapper {
            padding-left:0 !important;
            padding-right:0 !important;
        }
        
    }

    &-Wrapper {
        margin-bottom: 6rem;           
    }

    &-ColumnTitle {
        --footer-column-title-font-size: 2rem;
        --footer-column-title-font-weight: 500;
    }

    &-ColumnContent {
    
        h4, p, li {
            --footer-content-font-size: 1.4rem;
        }

        p, li {
            color: var(--footer-menu-link-color);
            line-height: 1.7;
        }
    }

    &-Newsletter {

        .CmsBlock-Wrapper {
            font-size: 1.4rem;
            color: var(--footer-menu-link-color);
            line-height: 1.7;
            margin-bottom: 2rem;
        }
    }
}

.FooterMenu {

    &-Link {

        & + .FooterMenu-ListItemContent {
            margin-top: 1rem;
        }
    }

    &-ListItem {

        > div:first-of-type {
            --footer-content-font-size: 1.4rem;

            font-size: var(--footer-content-font-size);
            text-transform: inherit !important;
            font-weight: inherit !important;
        }

        &.no-title {

            .FooterMenu-Link {
                display: none;
            }

            .FooterMenu-ListItemContent {
                margin-top: 0;
            }
        }
    }

    &-ListItemContent {

        strong {
            text-transform: uppercase;
        }

        ul {

            li {
                padding: 0;
                margin-bottom: 1rem;

                &:before {
                    display: none;
                }
            }
        }

        .SaasButtonWidget {

            &-Wrapper {
                margin-top: 1.5rem;
                padding: 0;
            }

            &-Button {
                border-radius: 0.5rem;
                height: 3.7rem;
                padding: 0.5rem 1rem;
                display: flex;
                align-items: center;

                background-color: #272323;
                
                color: #fff !important;
                --button-border: transparent;
                min-width: 12rem;
        
                &:not([disabled]):hover {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-radius: 0.5rem;
                    color: #fff !important;
                    background-color: #3E3939;
                    border-color: #272323;
                }

            }
        }
    }
}

.NewsletterSubscription {

    .FieldForm {

        .Button {
            background-color: var(--primary-dark-color) !important;
            border-color: var(--primary-dark-color) !important;
        }

        &-Button {
            --newsletter-subscription-button-background: #ED1941;

            border-radius: 0 2.5px 2.5px 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 !important;
            width: 5rem !important;

            &:before {
                display: none !important;
            }

            &:after {
                position: relative;
                display: inline-block;
                content: '';
                background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTY2LjkgMjY0LjVsLTExNy44IDExNmMtNC43IDQuNy0xMi4zIDQuNy0xNyAwbC03LjEtNy4xYy00LjctNC43LTQuNy0xMi4zIDAtMTdMMTI3LjMgMjU2IDI1LjEgMTU1LjZjLTQuNy00LjctNC43LTEyLjMgMC0xN2w3LjEtNy4xYzQuNy00LjcgMTIuMy00LjcgMTcgMGwxMTcuOCAxMTZjNC42IDQuNyA0LjYgMTIuMy0uMSAxN3oiPjwvcGF0aD48L3N2Zz4=');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                width: 3rem !important;
                height: 3rem !important;
                border: 0 !important;
                transform: none !important;
                transform-origin: inherit !important;
                top: auto !important;
                left: auto !important;
                position: relative !important;
            }

            &:hover,
            &:focus {
                border-color: lighten(#ED1941, 10%) !important;
                background-color: lighten(#ED1941, 10%) !important;
            }
        }
    }

    .FieldForm-Fieldset {

        .Field {

            input {
                border-radius: 2.5px 0 0 2.5px;
                border-right: 0;
            }
        }
        .Button {
            &:hover {
                background-color: #3E3939;
                color: #fff !important;
                border: none !important;
            }
        }
    }
}

@include mobile {

    .Footer {
        &-Column {
            &_isNewsletter {
                --footer-column-newsletter-background-color: #ED1941;
            }
        }

        &-ColumnTitle {
            cursor: pointer;
        }
    }

    .FooterMenu {
        &-ListItemContent {
            .SaasButtonWidget {
                &-Button {
                    margin: 0 auto;
                }
            }
        }
    }

    .NewsletterSubscription {
        .FieldForm {
            &-Fieldset {
                &.Field {
                    input {
                        border: 0;
                    }
                }
            }

            &-Button {
                --newsletter-subscription-button-background: #fff;

                &:not(:hover):not(:focus) {
                    &:after {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJhbmdsZS1yaWdodCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFuZ2xlLXJpZ2h0IGZhLXctNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIj48cGF0aCBmaWxsPSIjRUQxOTQxIiBkPSJNMTY2LjkgMjY0LjVsLTExNy44IDExNmMtNC43IDQuNy0xMi4zIDQuNy0xNyAwbC03LjEtNy4xYy00LjctNC43LTQuNy0xMi4zIDAtMTdMMTI3LjMgMjU2IDI1LjEgMTU1LjZjLTQuNy00LjctNC43LTEyLjMgMC0xN2w3LjEtNy4xYzQuNy00LjcgMTIuMy00LjcgMTcgMGwxMTcuOCAxMTZjNC42IDQuNyA0LjYgMTIuMy0uMSAxN3oiPjwvcGF0aD48L3N2Zz4=");
                    }
                }
            }
        }
    }
}

@include desktop {
    .Footer {

        &-Columns {
            padding: 6rem 1.5rem !important;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &-Column {
            width: 50%;
            margin-right: auto !important;
            max-width: 100% !important;
            margin-bottom: 5rem;

            .Footer-ColumnTitle,
            .Footer-ColumnContent {
                max-width: 320px;
            }

            &:last-of-type {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }

        &-ColumnTitle {
            --footer-column-title-font-size: 2.2rem;
        }
    }
}

@include realDesktopMedium {
    .Footer {
        &-Column {
            width: calc(25% - 6rem);
            margin-bottom: 0;
        }
    }
}