.ProductPrice {
    --price-discount-color: #000;
    --price-with-discount-color: var(--primary-dark-color);

    &-HighPrice {
        opacity: 1 !important;
        font-weight: 900 !important;
    }
}

.ProductCard {
    --product-card-padding: 1.5rem;
    --product-card-box-shadow: 0 0 5px #0000001A;
    --product-card-qty-input-box-shadow: 0 0 5px #0000001A;
    --product-card-label-group-children-padding: 2.2rem;

    min-height: 27rem;

    &-MagazineQty {
        bottom: 1.5rem;
        right: 1.5rem;
    }

    @include mobile {
        flex-direction: column !important;
    }

    &-PriceWrapper {
        flex-direction: row !important;
    }

    &-Qty {
        &.Field {

            @include desktop {
                margin: 0 !important;
            }

        }
    }

    &-Price {
        @include mobile{
            text-align: center;
        }

        &.ProductPrice_hasDiscount {
            .ProductPrice-HighPrice {
                text-decoration: unset;

                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: var(--primary-dark-color);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(-15deg);
                }
            }

            .ProductPrice-Price {
                order: 0;
                padding-right: 1rem;
            }

            .ProductPrice-PriceValue {
                margin-top: 0;
            }
        }
    }

    .SaasProductLabels {
        &-ProductLabelGroup_position_top{
            &-left {
                @include desktop {
                    flex-direction:row;
                    left: 1rem;
                    top: 1rem;
                }
                @include mobile {
                    flex-direction:column;
                    left: 0.5rem;
                    top: 0.5rem;
                }
            }
        }
        @include mobile {
            &-ProductLabel_hasShape:nth-of-type(1n+2):before {
                display:none;
            }
        }
    }

    &_listItemView_row {
        .ProductCard-Picture {
            @include desktop {
                padding-bottom: 175% !important;
                transform: scale(1) !important;
            }
            @include mobile {
                padding-bottom: 275% !important;
                transform: scale(1) !important;
            }
        }
    }

    .SaasProductTitleBlock {

        @include mobile {
            align-items: center !important;
        }

        h2 {
            font-size: 1.4rem !important;
            text-transform: uppercase;

            @include mobile {
                text-align: center !important;
            }
        }
    }

    &-AddToCart {
        margin-left: 0 !important;
    }

    .Button {

        > span {
            &:first-of-type {
                position: relative;

                &:after {
                    position: absolute;
                    top: 50%;
                    right: -2rem;
                    transform: translateY(-55%);
                    content: '+';
                    font-size: 2.5rem;
                    font-weight: 400;
                }
            }
        }
    }

    .SaasProductLabels {
        &-ProductDiscountLabel {
            --product-discount-label-border: none;

            &:before {

                display:none !important;
            }

            &:after {
                display:none !important;
            }
        }

        &-ProductDiscountLabelPercentage {
            --product-discount-label-font-size: 1.8rem;
            margin-top: -0.5rem;
        }
    }

    .ProductCard {
        > .ProductCard-Link,
        > .ProductCard-Content {
            padding-top: var(--product-label-group-children-padding) !important;
        }
    }
}

.ProductCard {
    .ProductActions {
        &-Qty {
            height: 5.3rem;

            .SaasInputSelectField {
                height: 100%;

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    content: '';
                    background: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTMxLjMgMTkyaDI1Ny4zYzE3LjggMCAyNi43IDIxLjUgMTQuMSAzNC4xTDE3NC4xIDM1NC44Yy03LjggNy44LTIwLjUgNy44LTI4LjMgMEwxNy4yIDIyNi4xQzQuNiAyMTMuNSAxMy41IDE5MiAzMS4zIDE5MnoiPjwvcGF0aD48L3N2Zz4=');
                    background-repeat: no-repeat;
                    background-size: 0.7rem;
                    background-position: center;
                    width: 0.7rem;
                    height: 0.4rem;
                }

                input {
                    border: none;
                    box-shadow: -1px 1px 3px rgba(0,0,0,0.13);
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                    font-size: 2rem;
                    min-width: 70px;
                }
            }
        }

        &-AddToCart {
            --button-height: 4.8rem;
            --button-padding: 1.2rem;
            --button-extra-padding-for-icon: 2rem;
            --button-padding-with-icon: calc(var(--button-padding) + var(--button-extra-padding-for-icon)) !important;
            padding-right: var(--button-padding-with-icon);
            box-shadow: 1px 1px 3px rgba(0,0,0,0.13);

            &:hover {
                border-radius: 0 0.5rem 0.5rem 0 !important;
            }
        }

        @include mobile {
            &-AddToCart {
                --button-padding: 1rem !important;
                padding-right: var(--button-padding-with-icon);
                padding-left: var(--button-padding);
            }
        }
    }

    @include mobile {
        .ProductCard {
            &-Content {
                height:100%;
            }

            &-Qty {
                max-width: 5rem ;
            }

            &-Footer {
                padding-top: 0 !important;
            }

            &-PriceWrapper {
                margin-bottom: 1rem !important;
            }

            .Button {

                > span {
                    &:first-of-type {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// ---------------------------------------
// Homepage
// ---------------------------------------

.HomePage {
    .SaasButtonWidget {
        &-Wrapper {
            --saasbuttonwidget-wrapper-padding: 0 1.5rem;
            --saasbuttonwidget-wrapper-margin: -2rem auto 0;
        }

        &-Button {
            --saasbuttonwidget-button-background: #FCC100;
            --saasbuttonwidget-button-color: #000;

            margin: 0 auto;
            border: none;
            border-radius: 5px;

            &_position_right {
                margin: 0 0 0 auto;

                @include mobile {
                    margin: 1rem auto;
                }
            }
        }
    }

    .LayoutGroupWidget-Container_layout_col_2_2 {
        .SaasSlider {
            &-Container {
                padding-bottom: 3rem;

                .swiper-button-prev,
                .swiper-button-next {
                    top: auto;
                    bottom: 0;
                    margin-top: 0;
                }
            }
        }

        .SaasSliderWidget {
            .swiper-button-prev,
            .swiper-button-next {
                transition: 200ms;

                &:not(.swiper-button-disabled):hover {
                    opacity: 0.5;
                }
            }

            .swiper-button-prev:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA4MCIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjMjcyMzIzIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHJ4PSIyMi41IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iLTAuNSIgeT0iLTAuNSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIGZpbGw9Im5vbmUiLz4KICAgIDwvZz4KICAgIDxwYXRoIGlkPSJjaGV2cm9uLXJpZ2h0LWxpZ2h0IiBkPSJNMTYuOTgsMzMuMWwuMzA3LjMwN2EuNTIuNTIsMCwwLDEsMCwuNzM2TDguODA5LDQyLjYyMywxNy4yODcsNTEuMWEuNTIuNTIsMCwwLDEsMCwuNzM2bC0uMzA3LjMwN2EuNTIuNTIsMCwwLDEtLjczNiwwTDcuMDkyLDQyLjk5MWEuNTIuNTIsMCwwLDEsMC0uNzM2TDE2LjI0NCwzMy4xQS41Mi41MiwwLDAsMSwxNi45OCwzMy4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuMDYxIC0xOC43MDcpIi8+CiAgPC9nPgo8L3N2Zz4K") !important;
            }

            .swiper-button-next:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA4MCIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjMjcyMzIzIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHJ4PSIyMi41IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iLTAuNSIgeT0iLTAuNSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIGZpbGw9Im5vbmUiLz4KICAgIDwvZz4KICAgIDxwYXRoIGlkPSJjaGV2cm9uLXJpZ2h0LWxpZ2h0IiBkPSJNNy40LDMzLjFsLS4zMDcuMzA3YS41Mi41MiwwLDAsMCwwLC43MzZsOC40NzgsOC40NzhMNy4wOTIsNTEuMWEuNTIuNTIsMCwwLDAsMCwuNzM2bC4zMDcuMzA3YS41Mi41MiwwLDAsMCwuNzM2LDBsOS4xNTMtOS4xNTNhLjUyLjUyLDAsMCwwLDAtLjczNkw4LjEzNCwzMy4xQS41Mi41MiwwLDAsMCw3LjQsMzMuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjU2IC0xOC43MDcpIi8+CiAgPC9nPgo8L3N2Zz4K") !important;
            }

            @include mobile {
                .swiper-button-prev:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctbGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzgwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA4MCIgZmlsbD0icmdiYSgwLDAsMCwwLjM5KSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHJ4PSIyMi41IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iLTAuNSIgeT0iLTAuNSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIGZpbGw9Im5vbmUiLz4KICAgIDwvZz4KICAgIDxwYXRoIGlkPSJjaGV2cm9uLXJpZ2h0LWxpZ2h0IiBkPSJNMTYuOTgsMzMuMWwuMzA3LjMwN2EuNTIuNTIsMCwwLDEsMCwuNzM2TDguODA5LDQyLjYyMywxNy4yODcsNTEuMWEuNTIuNTIsMCwwLDEsMCwuNzM2bC0uMzA3LjMwN2EuNTIuNTIsMCwwLDEtLjczNiwwTDcuMDkyLDQyLjk5MWEuNTIuNTIsMCwwLDEsMC0uNzM2TDE2LjI0NCwzMy4xQS41Mi41MiwwLDAsMSwxNi45OCwzMy4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAuMDYxIC0xOC43MDcpIiBmaWxsPSIjZmZmIi8+CiAgPC9nPgo8L3N2Zz4K") !important;
                }

                .swiper-button-next:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NyIgaGVpZ2h0PSI0NyIgdmlld0JveD0iMCAwIDQ3IDQ3Ij4KICA8ZyBpZD0iYXJyb3ctcmlnaHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiPgogICAgPGcgaWQ9IlJlY3RhbmdsZV84MCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgODAiIGZpbGw9InJnYmEoMCwwLDAsMC4zOSkiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIj4KICAgICAgPHJlY3Qgd2lkdGg9IjQ1IiBoZWlnaHQ9IjQ1IiByeD0iMjIuNSIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxyZWN0IHg9Ii0wLjUiIHk9Ii0wLjUiIHdpZHRoPSI0NiIgaGVpZ2h0PSI0NiIgcng9IjIzIiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgICA8cGF0aCBpZD0iY2hldnJvbi1yaWdodC1saWdodCIgZD0iTTcuNCwzMy4xbC0uMzA3LjMwN2EuNTIuNTIsMCwwLDAsMCwuNzM2bDguNDc4LDguNDc4TDcuMDkyLDUxLjFhLjUyLjUyLDAsMCwwLDAsLjczNmwuMzA3LjMwN2EuNTIuNTIsMCwwLDAsLjczNiwwbDkuMTUzLTkuMTUzYS41Mi41MiwwLDAsMCwwLS43MzZMOC4xMzQsMzMuMUEuNTIuNTIsMCwwLDAsNy40LDMzLjFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC41NiAtMTguNzA3KSIgZmlsbD0iI2ZmZiIvPgogIDwvZz4KPC9zdmc+Cg==") !important;
                }
            }

            &-Heading {
                font-size: 2.5rem;
                font-weight: 500;
                margin-top: 0;
                line-height: 1.1;
                text-align: center;
            }

            .ProductCard {
                flex-direction: column;

                &-Content {
                    max-width: none !important;
                    margin: 0 auto;
                    text-align: center;
                }

                &-Footer {
                    justify-content: center;
                }
            }

            &_totalItems_1 {
                .ProductCard {
                    &_listItemView_row > a {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include mobile {
        .SaasSliderWidget {
            &_totalItems {
                &_2 {
                    .ProductCard {
                        .ProductActions {
                            &-AddToCart {
                                --button-padding: 0.5rem !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @include desktop {
        .SaasSliderWidget {
            &-Heading {
                font-weight: 500;
                text-align: left;
            }
        }

        .LayoutGroupWidget-Container_layout_col_2_2 {
            .SaasSlider {

                &-Container {
                    padding-bottom: 0;
                    padding-top: 5rem;
                    margin-top: -5rem;

                    .swiper-button-prev,
                    .swiper-button-next {
                        top: 0;
                        transition: 200ms;

                        &:before {
                            position: absolute;
                            content: '';
                            left: 100%;
                            width: 2rem;
                            height: 0.1rem;
                            border-top: 1px dashed #000;
                        }
                    }

                    .swiper-button-prev {
                        left: auto;
                        right: 8.5rem;
                        margin: 0;
                    }

                    .swiper-button-next {
                        left: auto;
                        right: 2rem;
                        margin: 0;
                    }
                }
            }

            .SaasSliderWidget {
                &-Heading {
                    font-size: 2.6rem;
                    text-align: left;
                    padding-right: 12rem;
                }
            }

            .ProductCard {
                .SaasProductTitleBlock {
                    h2 {
                        padding: 0.3rem 0;
                    }
                }

                &-Picture {
                    padding-bottom: 88%;
                }
            }
        }
    }
}

.PageHeader {
    &-ContentProduct {
        @include mobile {
            .ProductCard {
                &-Content {
                    margin-top: auto;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
