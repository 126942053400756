.ProductPage {

    @include mobile {
        padding-bottom:2rem !important;
    }

    &_hasThreeColumns {
        --product-gallery-max-width: 31.3333%;
        --product-actions-col-3-max-width: 31.3333%;
        --product-attribute-icons-max-width: 31.3333%;
    }

    &-Wrapper {
        @include mobile {
            padding-top: 1.4rem;
        }
    }

    .ProductGallery {
        .SaasProductLabels {
            &-ProductDiscountLabel {
                --product-discount-label-width: 97px;
                --product-discount-label-height: 97px;
                font-size: 1.4rem;
            }

            &-ProductDiscountLabelPercentage {
                font-size: 3rem;
            } 
        }
    }
    
    .ProductSectionNavigation {
        &-ItemReviews {
            .BannerGroup  {
                &-Container {
                    padding:0;
                }
            }
        }
       
        @include mobile {
            &-ItemInner {
                &_info_button {
                    padding:0 !important;
                }
            }
        }
    }

    .ProductActions {

        @include mobile {
            order:2 !important;

            .SaasProductTitleBlock {
                display:none;
            }
        }

        @include mobile {

            &-MobileTitle {
                .ProductActions{
                    &-Title {
                        font-size: 2.2rem;
                        text-align: center;
                        line-height: 1;
                    }
                }

                .SaasProductTitleBlock {
                    width:100%;
                    text-align: center;

                    &-AttributeSuper {
                        display:none
                    }

                    &-AttributeSub {
                        text-align: center;
                        display: inline-block;
                    }
                }
            }
        }

        .ProductWishlistButton-Button.Button {
            &:hover {
                background-color: unset !important;
                height: var(--wishlist-heart-size) !important;
                padding: 0 !important;
            }

            .HeartIcon {
                &:hover {
                    stroke: var(--primary-base-color);
                    fill: var(--primary-base-color);
                }
            }
        }

        .ProductWishlistButton-Button.Button {
            &:focus {
                background-color: unset !important;
                height: var(--wishlist-heart-size) !important;
                padding: 0 !important;
            }
        }

        &-ShortDescription {
            line-height: 1.8;
            font-weight: 300;
        }

        &-Usps_isPDFDownloadVisible {
            margin-bottom: 3rem;

            @include desktop {
                margin-bottom: 1rem;
            }

            @include mobile {
                margin-bottom: -0.5rem;
            }
        }

        &-AddToCartWrapper {
            @include mobile {
                margin-top:0 !important;
            }

            @include desktop {
                padding-top: 0 !important;
            }
        }

        .SiteUsp {
            &-Content {
                --site-usp-font-size: 1.6rem;

                line-height: 1.8;
                font-weight: 300;
            }
        }
        &-PDFDownLoader {
            @include mobile {
                margin-bottom:2rem;
            }

            a {
                color:#272323;

                img {
                    width: 3rem;
                }
            }
        }
    }

    .ProductAttributeIcons {

        &-Item {
            width: 33.333%;
            padding:0 !important;

            @include desktop {
                padding: 0 0 0.5rem 0;
            }
        }

        &-ItemLabel {
            font-weight: 300;
        }

        @include mobile {
            margin-top:2rem;
        }

        .ProductActions {
            &-Usps {
                display: none;
            }
        }
    }

    .SaasProductTitleBlock {
        h2 {
            font-weight: 500;
        }

        &-AttributeSubValue,
        &-AttributeSuperValue {
            --attribute-label-item-value-font-weight: 300;
        }
    }

    .ProductSectionNavigation {
        &-ItemInner {
            .ProductInformation,
            .ProductAttributes {
                &-ExpandableContentContent {
                    * {
                        font-size: 1.4rem;
                        line-height: 1.8;
                        font-weight: 300;
                
                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        .ProductAttributes {

                            &-AttributeLabel{
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                            }
                            &-ValueLabel {
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }

                &-ExpandableContentButton {
                    .ExpandableContent {
                        &-Heading {
                            font-size: 2.4rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .ProductGallery {
            .SaasProductLabels {
                &-ProductLabel {
                    --product-label-padding: 1.2rem 1rem;
                }
            }
        }

        .ProductLinks {
            background: #F9F9F9;
            border-top: 1px solid #DDDDDD;
            padding: 4rem 0 6rem;
            margin-top: 4rem;
            position: relative;

            @include mobile {
                margin-left:-1.5rem;
                margin-right: -1.5rem;
                width: calc(100% + 3rem);
                margin-top: -0.1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                

                .ProductLinks {
                    &-Title {
                        font-size: 2.2rem;
                    }
                    &-List {
                        grid-gap: 10px;

                        .ProductCard {
                            flex-direction: column;
                            width: calc(50% - 0.5rem);
                            align-items: center;
                            text-align: center;
                            height:auto;

                            &-Name {
                                align-items: center;
                            }

                            &-PriceWrapper {
                                text-align: center;
                            }

                            &:nth-child(4){
                                display:none;
                            }

                            @include mobile {

                                &:nth-child(3){
                                    display:none;
                                }
                            }
                        }
                    }
                }
            }

            &:before,
            &:after {
                content: '';
                display: block;
                background: #F9F9F9;
                border-top: 1px solid #DDDDDD;
                width: 100%;
                height: 100%;
                position: absolute;
                top: -1px;
            }

            &:before {
                left: -100%;
            }

            &:after {
                right: -100%;
            }

            &-Title {
                text-align: center;
                text-transform: inherit;
                margin: 0 0 3rem !important;
                font-size: 3rem;
                color: #272323;
                font-weight: 500;
                line-height: 1.15;

                > span {
                    color: #ED1941;
                }
            }

            &-List {
                display: flex;
                flex-wrap: wrap;

                .ProductCard {
                    align-items: flex-start;
                    @include desktop {
                        width: 100%;
                    }
                }
            }

            .SaasProductLabels {

                &-Wrapper {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
        
                &-ProductLabelGroup_position_top-left {
                    --product-label-group-top: 2rem;
                    --product-label-group-left: 2rem;

                    & ~ * {

                        @include desktop {
                            padding-top: 4rem !important;
                            margin-top: auto;
                        }
                    }
                }
            }

            .ProductCard {
                background-color: #fff;
                box-shadow: 0px 0px 5px #0000001A;
                max-width: 390px;
                margin: 0 auto;

                .ProductActions {

                    &-Title {
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    @include mobile {
        .ProductGallery {
            padding-top: 1rem;
            border-top: 0.1rem solid #ddd;
            margin-left: -1.4rem !important;
            margin-right: -1.4rem !important;
            width: calc(100% + 2.8rem) !important;
        }

        & + .Footer {
            margin-bottom: 82px;
        }

        .ProductActions {
            &-AddToCartWrapper {
                bottom: 0 !important;
            }
        }
    }

    @include desktop {
        &-Wrapper {
            &_hasThreeColumns {
                grid-column-gap: 4%;
                grid-template-columns: 48% 48% !important;
            }
        }

        .ProductPage {
            &-Wrapper {
                grid-column-gap: 4rem;
            }
        }

        .ProductActions { 
            &-Usps {
                margin-top: 4rem;
            }
        }

        .ProductAttributeIcons {
            &-ItemImage {
                max-height: 9rem;
                margin-bottom:0.5rem;
            }
        }

        .ProductSectionNavigation {
            .ProductLinks {
                &-List {
                    .ProductCard {
                        max-width: 100%;
                        margin: 0;
                        width: 33%;
                    }
                }
            }
        }
    }

    @include realDesktopMedium {
        .ProductSectionNavigation {
            .ProductLinks {
                &-List {
                    .ProductCard {
                        width: 25% !important;
                    }
                }
            }
        }
    }

    .ProductInformation {
        --product-information-heading-font-size: 1.4rem;
        --product-information-description-margin: 0;

        @include desktop {
            --product-information-heading-font-size: 1.6rem;
        }

        &-Description {
            font-size: var(--product-information-description-font-size);
            line-height: 1.8;
        }
        
    }
}

@include mobile {
    .ProductSectionNavigation {
        &-ItemReviews{
            margin-top: 2rem !important;
        }
    }
}

//dit moet standaard in scandi
.ProductReviewForm {
    &-Wrapper {
        grid-template-columns: 1fr !important;
        grid-gap: 0 !important;
    }
}

.ProductReviews {
    
    &-ExpandableContentContent {

        @include desktop {
            padding-right:3rem;
        }

        .StarIcon {
            fill:#A6905C;
            width: 2.5rem;
            height: 2.5rem;
        }

        .ProductReviewItem {
            display:flex !important;
            flex-direction: column !important;

            &-RatingSummaryItem {
                border-bottom: 0.1rem solid #ddd;
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}

.ProductWishlistButton{

    &-Button  {

        &:hover {
            background-color: transparent !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: auto !important;
        }
    }
}

.ProductUsp {

    &-Icon {
        min-width: 3rem;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem !important;

        &:before {
            font-size: 2rem;
        }
    }
}

.ExpandableContent {

    @include mobile {

        &-Content {
            max-height: unset !important;
            overflow: visible !important;
            opacity: 1 !important;
        }

        &-Button  {
            pointer-events: none;

            svg {
                display:none;
            }
        }
    }
}


@include mobile {
    .ProductInformation {
        &-Heading {
            text-transform: none !important;        
        }
    }
}