@import './global';
@import './components/header';
@import './components/footer';
@import './components/banners';
@import './components/recepten-banners';
@import './components/eventcalendar';
@import './components/saasgooglemaps';
@import './components/productcard';
@import './components/product-add-to-card';
@import './components/product-labels';
@import './components/swiper';
@import './components/storelocator';
@import './components/categoryheader';
@import './components/video-widget';
@import './components/salespromotionpage';
@import './components/storelistpage';
@import './components/cookie';
@import './components/wishlist';
@import './components/SaasSlider.style';
@import './components/newsletterSubscription';
@import './pages/account';
@import './pages/product-page';
@import './pages/cart-page';
@import './pages/checkout-page';
@import './pages/success-page';

:root {
    --button-border-radius: 0.5rem;
}

body, .CategoryFilterOverlay.Overlay,
.Overlay > .CategoryFilterOverlay-Wrapper,
body main.ProductPage {
    background-color: #fbfbfb;
}

// _reset.scss overwrites
a + a {
    margin-left: inherit;
}

h2 + h3,
h2 + h4 {
    top: auto;
}

h4, p, li, dl {
--paragraph-font-size: 1.6rem;
    line-height: 1.8;
}

.CmsPage {
    h1,
    h2,
    h3 {
        text-transform: unset !important;
    }
}

// end _reset.scss overwrites

.Popup {
    z-index: 10000;
    --popup-background: rgba(0,0,0,0.7);
}

.PageHeader {
    &-Image {
        margin-top: -2rem;
    }
}

.ProductCard,
.ContentBlock-Content,
.rr-item__container,
.ProductGallery {
    background-color: white;
}

.Breadcrumbs {
    z-index:15;
}

.SaasSliderWidget {
    &-Heading {
        text-align: center;
        font-weight: 500;

        @include desktop {
            font-size: 2.5rem;
        }

        @include mobile {
            font-size: 2.2rem !important;
        }
    }
}

.HomePage {

    .CmsPage {
        &-Wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .ContentBlock {

        &-Title {

            h2 {
                --content-block-title-font-size: 2.5rem;
                font-size: var(--content-block-title-font-size);

                text-align: center;
                font-weight: 500;
            }
        }
    }

    @include desktop {


        .ContentBlock {
            &-Title {
                h2 {
                    --content-block-title-font-size: 2.6rem;
                    font-size: var(--content-block-title-font-size);

                    text-align: left;
                }
            }
        }
    }
}

.AgeCheckPopup{
    &-SubmitButton {
        --button-color: #272323;
    }
}

.ProductReviewForm,
.SaasButtonWidget,
.StoreListItem-ButtonWrapper,
.LoginAccount-CreateAccount,
.MyAccount:not(.Button_isHollow),
.MyAccountOverlay-SignInButton,
.MyAccountNewsletterSubscription,
.CheckoutShipping,
.CartOverlay,
.CartPage,
.GiftCard,
.CartCoupon,
.MyAccountMyWishlist {

    .Button {
        border-radius: 0.5rem;

        &:not([disabled]):hover {
            border-radius: 0.5rem;
        }
    }

    &-CheckoutButton,
    &-CheckoutButton,
    &-FormSubmitButton,
    &-Button,
    .Button:not(.Button_isHollow) {
        color:#272323 !important;
        --button-border: transparent;
        --button-background: #FCC100;

        svg {
            fill:#272323 !important;
        }

        &:not([disabled]):hover {
            --button-hover-background: #E3A800;
            background-color: #E3A800;
            border:none;
            color:#272323;

            svg {
                fill:#272323;
            }
        }
    }
}

.CookiePopup {
    display: none !important;
}

@include mobile {
    .MyAccount {
        padding-bottom: 1.5rem;
    }

    .MyAccountTabList {
        &-ExpandableContentContent {
            padding:0 1.5rem;
        }
    }
}
