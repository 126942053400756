.CookiePopup {
    justify-content: center !important;
    padding:0.5rem 1rem !important;
    box-shadow: 0px 0px 15px -2px rgba(0,0,0,0.20);

    @include desktop {
        padding:0.5rem 1rem !important;
    }
    
    &-Content {
        @include desktop {
            padding:0 1rem;
        }
        @include mobile {
            padding:2rem 1rem;
            margin-bottom: 0;
            text-align: center;
            font-size: 2rem !important;
            line-height: 1.6;
        }
        a {
            text-decoration: underline;
        }
    }

    &-CTA {
        background-color:#272323 !important;
        border-radius: 0.5rem !important;
        border: 0.1rem solid #272323;

        &:hover {
            --button-hover-border:#272323;
            background-color: darken(#272323,10) !important; 
            border-color: var(--button-hover-border);
        }
    }

    @include mobile {
        flex-direction: column;
        top: 132px;
        left: 0 !important;
        right: 0 !important;
        z-index: 9001 !important;
        padding:0.5rem 3rem !important;
    }
}