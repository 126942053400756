.CategoryHeader {
    --category-header-product-background-color: var(--theme-secondary-color);
}

.PageHeader {
    --attribute-label-item-value-color: #fff;

    &-ContentProduct {
        @include mobile {
            background-color: #a6905c !important;
        }

        .ProductCard {
            @include mobile {
                padding: 1rem !important;
                flex-direction: row !important;
                display: flex;
                flex-wrap: nowrap;
            }

            .SaasProductTitleBlock {
                @include mobile {
                    --product-card-saas-product-title-block-min-height: 7rem;
                    
                    justify-content: start;
                    margin-bottom: 0;

                    > * {
                        justify-self: start;
                    }
                }

                &-AttributeSuper {
                    color: #fff;
                }
            }

            .ProductActions-Title {
                --product-title-hightlight-color: #fff;
                color: var(--attribute-label-item-value-color);
            }

            > .ProductCard-Link {
                @include mobile {
                    padding-top: 0 !important;
                }
            }

            &-Picture {
                padding-bottom: 135%!important;

                @include mobile {
                    padding-bottom: 230% !important;
                }
            }

            &-Content {
                max-width: 100% !important;

                @include mobile {
                    margin-top: 2rem;
                }

                > .ProductCard-Link {
                    @include mobile {
                        align-items: flex-start;
                        margin-top: 4rem;
                    }
                }
            }

            @include mobile {
                &_listItemView_row {
                    .ProductCard-Footer {
                        padding-top: 0;
                    }
                }
            }

            &-Link {
                max-width: 100% !important;
            }

            &-Price {
                .ProductPrice-PriceValue {
                    color: var(--attribute-label-item-value-color) !important;
                }

                .ProductPrice-HighPrice {
                    color: #fff !important;
                }
            }

            &-Footer {
                @include mobile {
                    justify-content: start;
                }
            }
        }
    }
}