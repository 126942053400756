.SaasHeader {
    --site-usp-font-size: 1.4rem;
    --site-usp-icon-font-size: 2rem;

    &-MobileUsps {
        .swiper-wrapper {
            padding-bottom: 0 !important;
        }
    }

    &-NavLinks {
        width: auto !important;
    }

    &-Switcher {
        display: none;
    }

    &-LogoWrapper {
        .Image {

            &-Image {
                position: relative;
                top: auto;
                left: auto;
                width: auto;
                height: auto;
                object-position: initial;
                display: block;
                max-width: 100%;
            }
        }
    }

    .CartOverlay {
        top: 4rem !important;
    }

    .MenuAdvanced {
        &-Overlay{
            .MenuNavigation-CloseButton {
                background-color: rgba(0, 0, 0, 0.55);
            }
        }

        &-MainItem {
            margin: 0 !important;
            padding: 0 1.5rem !important;

            @media screen and (max-width: 1240px) {
                display: none;
            }
        }

        &-Link {
            @include desktop {
                font-size: 1.4rem !important;
            }
            text-transform: uppercase !important;
        }
    }

    .MenuButton {
        --header-menu-button-active-color: transparent;

        @include desktop {
            padding: 0 1.5rem;
            margin: 0;
        }

        &-Inner {
            @include realDesktop {
                margin-top: 0 !important;
                padding-top: 1rem !important;
            }
        }
    }

    @include mobile {

        &-NavMenu {
            height: 100%;
        }

        &-NavLinks {
            height: auto !important;
        }

        &-Wrapper {
            --header-wrapper-height: auto;
        }
    }

    &-MinicartItemCount {
        background: #ED1941 !important;
        padding:0;
        width:2rem !important;
        height:2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -1rem;
        top: -1rem;
        padding: 0.3rem 0 !important;

        @include mobile {
            right: -1rem;
            top: -0.6rem !important;
            left: auto !important;
        }
    }

    &-Usps {
        .ContentWrapper {
            display: flex !important;
            padding: 0 1rem 0 1.5rem;
        }
    }
}

.KiyohSnippet {
    margin-left:auto;

    &-TotalScore {
        width: 3.3rem !important;
        height: 3.3rem !important;
        font-size: 1.5rem !important;
        font-weight: 700;
    }
}
.CartItem {
    &-Qty {
        .SaasInputSelectField {
            &-Options {
                &_isExpanded {
                    max-height: 7.8rem !important;
                }
            }
        }

        input {
            text-align: left;
            text-indent: 1.3rem;
            font-size: 1.4rem;
        }
    }
}

.SaasHeader{
    &-MobileUsps {
        border-bottom:0.1rem solid #ddd;
    }
}

.PageHeader {
    @include mobile {
        &-ContentWrapper {
            --category-image-height: 28rem;
        }
    }

    &-ContentText {
        h1 {
            text-shadow: 0.1rem 0.1rem 0 rgba(0,0,0, 0.5);
        }
        p {
            text-shadow: 0.1rem 0.1rem 0 rgba(0,0,0, 0.5);

            @include mobile {
                line-height: 1.3;
                font-size: 1.5rem;
            }
        }
    }
}

//wederom in scandi is deze berekening top niet goed
@include mobile {
    .MyAccountOverlay {
        top:5.2rem !important;
    }
}
