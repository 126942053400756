.SaasProductLabels {
    --product-discount-label-background-color: #EC1A42;
    --product-discount-label-text-color: #fff;
    --product-discount-label-font-size: 1.6rem;
    --product-discount-label-margin: var(--product-label-margin);
    --product-discount-label-padding: var(--product-label-padding);
    --product-discount-label-percentage-text-color: var(--product-label-text-color);
    --product-discount-label-width: 10rem;
    --product-discount-label-height: 10rem;
    --product-discount-label-border: 0.75rem solid #727464;
    --product-discount-label-border-radius: 50%;
    --product-discount-label-percentage-font-size: 2.7rem;
    --product-discount-label-percentage-font-size: var(--product-discount-label-text-color);

    &-ProductLabel {
        --product-label-border: 0;
        --product-label-padding: 0 0.5rem 0 1rem;
        --product-label-background-color: #ececec;
        --product-label-margin: 0 2rem 0.5rem 0;
        --product-label-height: 3rem;
        text-transform: uppercase;
        display:flex;
        align-items: center;

        &_hasShape {
            &:nth-of-type(1n+2) {
              padding-left: 0.5rem;
    
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -1rem;
                width: 3rem;
                height: 100%;
                background: inherit;
                -webkit-clip-path: polygon(100% 0,100% 50%,100% 100%,0 100%,25% 50%,0 0);
                clip-path: polygon(100% 0,100% 50%,100% 100%,0 100%,25% 50%,0 0);
              }
            }
    
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: -1rem;
              width: 3rem;
              height: 100%;
              background: inherit;
              -webkit-clip-path: polygon(75% 0,100% 50%,75% 100%,0 100%,0 0);
              clip-path: polygon(75% 0,100% 50%,75% 100%,0 100%,0 0);
            }
        }
    }

    &-ProductDiscountLabel {
        --product-discount-label-border: 6px solid #727464;
        --product-discount-label-background-color: var(--primary-dark-color);
        color: #fff !important;
        font-weight: 700;
        position: relative;
        
        &:before {
            content: '';
            background-color: #272323;
            width: 106px;
            height: 106px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border-radius: 50%;
        }

        &:after {
            content: '';
            width: 12rem;
            height: 12rem;
            background-color: #282424;
            display: block;
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    &-ProductDiscountLabelText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-weight: bold;
    }

    &-ProductDiscountLabelPercentage {
        --product-discount-label-font-size: 3rem;
        --product-discount-label-text-color: #fff;
        font-weight: 700;
    }
}

.PageHeader {
    &-ContentProduct {
        .SaasProductLabels {
            &-ProductDiscountLabel {

                &:after {
    
                    @include mobile {
                        width: 6.5rem;
                        height: 6.5rem;
                    }
                }
            }

            &-ProductLabelGroup {
                &_position_top-left {
                    @include mobile {
                        left: 12.7rem;
                        top: 2rem;
                        flex-direction: row;
                    }
                    @include desktop {
                        left: 19.3rem;
                        top: var(--category-header-product-card-padding);
                    }
                    @include wide-desktop-plus {
                        left: 21.8rem;
                    }
                }
            }

            @include mobile {
                &-ProductLabel_hasShape:nth-of-type(1n+2):before {
                    display:block !important;
                }
            }
        }

        .ProductCard_hasProductLabels {
            --product-card-label-group-children-padding: 3rem;
        }
    }
}